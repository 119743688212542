<template>
  <v-dialog v-model="dialog" width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Modificar Curso</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-text-field
                label="Nombre del Curso"
                required
                outlined
                hide-details
                v-model="course.course"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Codigo del Curso"
                required
                outlined
                hide-details
                v-model="course.code"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Horas del Curso"
                required
                outlined
                hide-details
                v-model="course.hours"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field
                label="Precio del Curso"
                required
                outlined
                hide-details
                v-model="course.price"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="8">
              <v-select
                label="Personal encargado"
                :items="personals"
                item-text="email"
                item-value="id_personal"
                required
                outlined
                hide-details
                v-model="course.id_personal"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Nota mínima de aprobación"
                required
                outlined
                hide-details
                v-model="course.minimum_score"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-select
                label="Programa del Curso"
                :items="programs"
                item-text="program"
                item-value="id_program"
                required
                outlined
                hide-details
                v-model="course.id_program"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Aula del Curso"
                :items="classrooms"
                item-text="classroom"
                item-value="id_classroom"
                required
                outlined
                hide-details
                v-model="course.id_classroom"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
                label="Ciclo para el Curso"
                :items="cycles"
                item-text="cycle"
                item-value="id_cycle"
                required
                outlined
                hide-details
                v-model="course.id_cycle"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-autocomplete
                label="Libro para el Curso"
                :items="books"
                item-text="book"
                item-value="id_book"
                required
                outlined
                hide-details
                v-model="course.id_book"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-checkbox
                v-model="course.book_required"
                label="Obligatorio"
                color="orange"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="course.id_schedules"
                :items="schedules"
                item-text="schedule"
                item-value="id_schedule"
                label="Horarios para el Curso"
                multiple
                chips
                deletable-chips
                outlined
                hide-details
                hint="Seleccione los horarios del curso"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.day }}
                  ({{ data.item.start }} a {{ data.item.end }})
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.day }}
                  ({{ data.item.start }} a {{ data.item.end }})
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container> </v-card-text
      ><v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateCourse",
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },
  props: {
    course: Object,
    personals: Array,
    programs: Array,
    classrooms: Array,
    cycles: Array,
    books: Array,
    schedules: Array,
    clean: { type: Function },
  },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionamiento
    update: function() {
      if (this.course.status != 3) {
        this.isLoading = true;
        this.$http
          .post(this.api + "course/" + this.course.id_course, this.course)
          .then(({ data }) => {
            this.openSnackbar({
              type: data.response ? "success" : "warning",
              message: data.message,
            });
            if (data.response) {
              this.changeModal();
              this.clean();
            }
          })
          .catch((err) => {
            this.openSnackbar({
              type: "danger",
              message: "Error al realizar la petición",
            });
          })
          .then(() => {
            this.isLoading = false;
          });
      } else {
        this.openSnackbar({
          type: "info",
          message: "El curso ha sido finalizado, no se permite su edición.",
        });
      }
    },
    changeModal: function() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style></style>
