<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Cursos
        </p>
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd()"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Curso</span>
          <span class="d-flex d-sm-none">Agregar</span>
        </v-btn>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="black--text">
              <v-icon left color="naranja">mdi-filter</v-icon>
              Filtros
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="Ciclo para el Curso"
                    :items="data_cycles"
                    item-text="cycle"
                    item-value="id_cycle"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.id_cycle"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="Programa del Curso"
                    :items="data_programs"
                    item-text="program"
                    item-value="id_program"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.id_program"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    label="Estado del Curso"
                    :items="course_status"
                    item-text="estado"
                    item-value="status"
                    required
                    outlined
                    hide-details
                    v-model="data.filters.status"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="filterData"
              :items-per-page="isDataBeingFiltered ? -1 : 15"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Cursos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            csv-title="Lista De Cursos"
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title @click="modalFilters()">
                          <v-icon>mdi-filter</v-icon>
                          Filtros
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- customs -->
              <template v-slot:[`item.classroom`]="{ item }">
                {{ item.classroom }}
                <a
                  v-if="validateURL(item.link)"
                  :href="item.link"
                  target="_blank"
                >
                  <v-icon small right color="primary">mdi-open-in-new</v-icon>
                </a>
              </template>
              <template v-slot:[`item.personal`]="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:[`item.book`]="{ item }">
                <div>
                  {{ item.book }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.book_required == 1"
                        small
                        color="azul"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>Libro Obligatorio</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.price`]="{ item }">
                $ {{ item.price }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ item.status | getStatus }}
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <div class="text-nowrap">
                  <template v-if="searchInArray(actions, 'Calificar')">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :to="'course/' + item.id_course">
                          <v-icon color="primary" v-bind="attrs" v-on="on">
                            mdi-account-group
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Estudiantes</span>
                    </v-tooltip>
                  </template>
                  <template v-if="searchInArray(actions, 'Editar')">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="
                            selectCourse(item);
                            modalUpdate();
                          "
                          color="amber"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                  <template
                    v-if="
                      searchInArray(actions, 'Eliminar') && item.status != 3
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="
                            selectCourse(item);
                            modalDelete();
                          "
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                  <template
                    v-if="
                      searchInArray(actions, 'Activar/Desactivar') &&
                        item.status != 3
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="
                            selectCourse(item);
                            changeStatus();
                          "
                          :color="
                            item.status == 1
                              ? 'light-blue darken-3'
                              : 'blue-grey darken-1'
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{
                            item.status == 1
                              ? 'mdi-eye-outline'
                              : 'mdi-eye-off-outline'
                          }}
                        </v-icon>
                      </template>
                      <span>
                        {{ item.status == 1 ? 'Desactivar' : 'Activar' }}
                      </span>
                    </v-tooltip>
                  </template>
                </div>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getCourses()">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modals -->
    <AddCourse
      ref="mdlAdd"
      :course="newCourse"
      :personals="data_personals"
      :programs="data_programs"
      :classrooms="data_classrooms"
      :cycles="data_cycles"
      :books="data_books"
      :schedules="data_schedules"
      :clean="cleanAdd"
      :reload="getCourses"
    ></AddCourse>
    <UpdateCourse
      ref="mdlUpdate"
      :course="slcCourse"
      :personals="data_personals"
      :programs="data_programs"
      :classrooms="data_classrooms"
      :cycles="data_cycles"
      :books="data_books"
      :schedules="data_schedules"
      :clean="cleanUpdate"
    ></UpdateCourse>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mb-0 mt-4">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteCourseConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';
import AddCourse from './AddCourse';
import UpdateCourse from './UpdateCourse';

export default {
  name: 'Course',
  components: {
    AddCourse,
    UpdateCourse,
    VueJsonToCsv,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: 'Curso', value: 'course' },
        { text: 'Codigo', value: 'code' },
        { text: 'Profesor', value: 'personal' },
        { text: 'Salón', value: 'classroom' },
        { text: 'Libro', value: 'book' },
        { text: 'Horas', value: 'hours' },
        { text: 'Precio', value: 'price' },
        { text: 'Programa', value: 'program' },
        { text: 'Ciclo', value: 'cycle' },
        { text: 'Estudiantes', value: 'count_students' },
        { text: 'Estado', value: 'status' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      data: [],
      filters: {},
    },
    course_status: [
      { estado: 'Activo', status: 1 },
      { estado: 'Desactivado', status: 2 },
      { estado: 'Finalizado', status: 3 },
    ],
    newCourse: {},
    slcCourse: {},
    dialogDelete: false,
    isLoading: false,
    search: '',
    //data
    data_personals: [],
    data_programs: [],
    data_classrooms: [],
    data_cycles: [],
    data_books: [],
    data_schedules: [],
    STORAGE_FILTERS_KEY: "courses_filters",
  }),
  mounted() {
    this.getCurrentActions('Cursos');
    //
    this.getPersonals();
    this.getPrograms();
    this.getClassrooms();
    this.getPrograms();
    this.getCycles();
    this.getBooks();
    this.getSchedules();
    this.getFiltersFromStorage();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: 'getCurrentActions',
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    //data
    getPersonals: function() {
      this.$http.get(this.api + '/personal/allB').then(({ data }) => {
        this.data_personals = data;
      });
    },
    getPrograms: function() {
      this.$http.get(this.api + 'program/all').then(({ data }) => {
        this.data_programs = data;
      });
    },
    getClassrooms: function() {
      this.$http.get(this.api + 'classroom/all').then(({ data }) => {
        this.data_classrooms = data;
      });
    },
    getCycles: function() {
      this.$http.get(this.api + 'cycle/all').then(({ data }) => {
        this.data_cycles = data;
      });
    },
    getBooks: function() {
      this.$http.get(this.api + 'book/all').then(({ data }) => {
        this.data_books = data;
      });
    },
    getSchedules: function() {
      this.$http.get(this.api + 'schedule/all').then(({ data }) => {
        this.data_schedules = data;
      });
    },
    //funcionalidad
    getCourses: async function() {
      this.data.load = true;
      let id = this.user.id_user;
      let route = 'course/personal/' + id;
      if (this.searchInArray(this.actions, 'Ver Todos')) {
        route = 'course/all';
      }
      await this.$http
        .get(this.api + route)
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener los datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectCourse(course) {
      if (!Array.isArray(course.id_schedules)) {
        course.id_schedules = course.ids_schedules.split(',').map((el) => {
          return parseInt(el);
        });
      }
      this.slcCourse = course;
    },

    cleanAdd() {
      this.newCourse = {};
      this.getCourses();
    },

    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcCourse = {};
      this.getCourses();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deleteCourseConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + 'course/' + this.slcCourse.id_course)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + 'course/status/' + this.slcCourse.id_course)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcCourse = {};
      });
    },

    //agrear a mixins
    validateURL: function(val) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return !!pattern.test(val);
    },
    saveFiltersOnStorage() {
      if (Object.keys(this.data.filters).length) {
        const parsedFilterObj = JSON.stringify(this.data.filters);
        sessionStorage.setItem(this.STORAGE_FILTERS_KEY, parsedFilterObj);
      }
    },
    getFiltersFromStorage() {
      const filters = sessionStorage.getItem(this.STORAGE_FILTERS_KEY);
      if (!filters) return;
      this.data.filters = JSON.parse(filters);
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      user: 'getUser',
      actions: 'getCurrentActions',
    }),
    isDataBeingFiltered() {
      return (this.data.filters?.id_cycle != null || this.data.filters?.id_cycle != undefined) || (this.data.filters?.id_program != null || this.data.filters?.id_program != undefined) || (this.data.filters?.status != null || this.data.filters?.status != undefined)
    },
    filterData() {
      this.saveFiltersOnStorage();
      //quitandole la reactividad
      let localDataJSON = JSON.stringify(this.data.data);
      let data = JSON.parse(localDataJSON);
      //descontruccion
      let { id_program, id_cycle, status } = this.data.filters;
      //filtros
      if (id_program != undefined) {
        data = data.filter((item) => item.id_program == id_program);
      }
      if (id_cycle != undefined) {
        data = data.filter((item) => item.id_cycle == id_cycle);
      }
      if (status != undefined) {
        data = data.filter((item) => item.status == status);
      }
      return data;
    },
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = ['Acciones', 'Programa'];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },
    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          //cambios por key
          for (var [key, value] of Object.entries(item)) {
            if (key == 'course') {
              item.course = item.program + ' ' + item.course;
            }
            if (key == 'first_name' || key == 'last_name') {
              item.personal = item.first_name + ' ' + item.last_name;
            }
            if (key == 'status') {
              item.status = this.$options.filters.getStatus(item.status);
            }
          }
          data.push(item);
        });
      }
      return data;
    },
  },
  filters: {
    getStatus(val) {
      switch (parseInt(val)) {
        case 1:
          return 'Activo';
        case 2:
          return 'Desactivado';
        case 3:
          return 'Finalizado';
        default:
          return '?';
      }
    },
  },
  watch: {
    actions(val) {
      this.getCourses();
    },
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
